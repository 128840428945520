import { Gantt } from '@klaro/corejs/views';
import { Board } from '@klaro/corejs/model';
import { ChartViewVariants } from '@klaro/corejs/state';
angular
    .module('klaro')
    .directive('boardDisplay', boardDisplay);
function boardDisplay($filter, Colors, navigation, summaryEditModal, summaryRest, $sce) {
    return {
        restrict: 'EA',
        template: require('@/core/board/boardDisplay.html'),
        link: function (scope, elm, attrs) {
            scope.getDisplayModes = Board.getDisplayModes().map((mode) => {
                const capitalizedName = $filter('capitalize')(mode.code);
                mode.icon = `mode-icon icon-View-${capitalizedName}`;
                return mode;
            });
            scope.getChartVariants = Object.entries(ChartViewVariants).map(([k, v]) => {
                return { code: k, label: v.label };
            });
            scope.$watch('boardData.mode', (mode) => {
                scope.currentMode = scope.getDisplayModes.find(m => m.code === mode);
            });
            scope.backgroundColors = Colors.backgrounds().map((bg) => {
                bg.label = $filter('capitalize')(bg.id);
                bg.code = bg.id;
                bg.icon = `icon-Color-rectangle ${bg.class}`;
                return bg;
            });
            scope.kanbanDimensions = $filter('orderBy')(scope.board.dimensions.filter((d) => {
                return !d.attribute;
            }), '+label');
            scope.cardDimensions = $filter('orderBy')(scope.board.dimensions.filter((d) => {
                return d.canBeUsedOnCards();
            }), '+label');
            scope.hasMultipleOrdering = function () {
                return scope.boardData.anchors.ordering
                    && scope.boardData.anchors.ordering.length > 1;
            };
            scope.addSummary = function () {
                summaryEditModal
                    .open(summaryRest.newOne())
                    .result
                    .then((summary) => {
                    scope.summaries = scope.summaries.concat([summary]);
                    scope.boardData.summaries.push(summary.toRaw());
                })
                    .catch(navigation.noop);
            };
            scope.ganttDatesDimensions = $filter('orderBy')(scope.board.getDateBoundaryDimensions(), '+label');
            scope.ganttScales = Object.values(Gantt.Scales).map((cs) => {
                if (cs.oneLetter) {
                    return {
                        code: cs.key,
                        label: cs.key.charAt(0).toUpperCase() + cs.key.slice(1),
                    };
                }
            }).filter(Boolean);
            scope.ganttScales.unshift({ code: null, label: '[Auto]' });
        },
    };
}

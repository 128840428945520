angular
    .module('klaro')
    .directive('storyMatrix', storyMatrix);
function storyMatrix(ActionsForHelper) {
    return {
        restrict: 'E',
        template: require('@/core/story/storyMatrix.html'),
        scope: {
            'onClick': '&',
            'canWrite': '=',
        },
        require: '^board',
        link: function (scope, elm, attrs, boardCtrl) {
            scope.boardCtrl = boardCtrl;
            function onBoardChanged() {
                scope.matrixView = boardCtrl.getBoard().matrixView;
            }
            boardCtrl.$watch('board', onBoardChanged, scope);
            scope.withNewColumnDimension = function (dimension) {
                return boardCtrl.mutate((b) => {
                    return b.addNewDimension(dimension).setDimensionsAt('displayBy', [dimension]);
                });
            };
            scope.withNewRowDimension = function (dimension) {
                return boardCtrl.mutate((b) => {
                    return b.addNewDimension(dimension).setDimensionsAt('rowsBy', [dimension]);
                });
            };
            scope.configureIt = function () {
                return boardCtrl.mutate((board) => {
                    return board
                        .setDimensionsAt('displayBy', [scope.matrixView.configuration.columnsBy])
                        .setDimensionsAt('rowsBy', [scope.matrixView.configuration.rowsBy]);
                });
            };
            scope.columnActionsFor = function (column) {
                if (!column.actions) {
                    column.actions = ActionsForHelper.actionsForMatrixColumn(boardCtrl, column);
                }
                return column.actions;
            };
            scope.cardActionsFor = function (row, column) {
                const cell = scope.matrixView.cellFor(row, column);
                if (!cell) {
                    return;
                }
                if (!cell.actions) {
                    cell.actions = ActionsForHelper.actionsForMatrixCell(boardCtrl, cell);
                }
                return cell.actions;
            };
            scope.patchOnDrop = function (row, column) {
                return function (storyIds) {
                    const dimensions = [row.dimension, column.dimension];
                    const values = [row.dimensionValue, column.dimensionValue];
                    scope.boardCtrl.moveStoriesToMultiple(storyIds, dimensions, values);
                };
            };
            scope.defaultsFor = function (row, column) {
                return Object.assign({}, row.defaults, column.defaults);
            };
            scope.withNewDimensionValue = function (dimension, dimensionValue) {
                boardCtrl.addDimensionValue(dimension, dimensionValue);
            };
        },
    };
}

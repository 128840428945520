import { TimeDuration, DateParser, ArrayUtils, } from '@klaro/corejs/utils';
import { LocalStoragePersistence } from '@klaro/corejs/utils';
import { BoardState } from '@klaro/corejs/state';
angular
    .module('templates', []);
const dependencies = [
    'klaro.kernel',
    'ngRoute',
    'ngSanitize',
    'ngMessages',
    'templates',
    'dndLists',
    'ui.bootstrap',
    'ui.gravatar',
    'yaru22.angular-timeago',
    'focus-if',
    'ngFileUpload',
    'cfp.hotkeys',
    'puigcerber.countryPicker',
    'tmh.dynamicLocale',
    '720kb.datepicker',
    'bootstrapLightbox',
];
if (process.env.SENTRY_DSN) {
    dependencies.push('ngSentry');
}
angular
    .module('klaro', dependencies)
    .constant('ArrayUtils', ArrayUtils)
    .constant('TimeDuration', TimeDuration)
    .constant('DateParser', DateParser)
    .run(() => {
    BoardState.setPersistence(new LocalStoragePersistence());
});

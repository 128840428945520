import DataObject from '../utils/DataObject';
class ActiveState extends DataObject {
    register(listener) {
        if (!this.__world || !this.__world.listeners) {
            this.__world = Object.assign({}, this.__world, { listeners: [] });
        }
        this.__world.listeners.push(listener);
        const state = this.factorInstance(this.__raw);
        return listener.stateChanged(this, state).then((s) => {
            return this.clone().__installRaw(s.__raw);
        });
    }
    factorInstance(raw) {
        return new ActiveState(raw);
    }
    with(overrides) {
        const oldState = this;
        const newState = oldState.clone(overrides);
        const init = Promise.resolve(newState);
        if (this.__world && this.__world.listeners) {
            return this.__world.listeners.reduce((p, l) => {
                return p.then(s => l.stateChanged(this, s, oldState));
            }, init);
        }
        else {
            return Promise.resolve(newState);
        }
    }
}
export default ActiveState;

angular
    .module('klaro')
    .service('ActionsForHelper', ActionsForHelper);
function ActionsForHelper($rootScope, dimensionValueEditModal, navigation) {
    const build = function (action, boardCtrl, column) {
        return function (event, params, options) {
            event.preventDefault();
            event.stopPropagation();
            const instance = action(boardCtrl, column);
            if (instance.enabled === undefined || instance.enabled()) {
                return instance.method(event, params, options);
            }
        };
    };
    const newCardAction = function (boardCtrl, column) {
        return {
            label: 'New card',
            method: (_event, _dimensionInfo) => {
                const defaults = column.defaults;
                boardCtrl.openNewStoryModal(defaults);
            },
            param: [column.dimension, column.dimensionValue],
        };
    };
    const editDimensionValueAction = function (boardCtrl, column) {
        return {
            label: 'Rename / Edit …',
            method: async (event, param, options) => {
                event.preventDefault();
                const dimension = param[0];
                const value = param[1];
                let dimensionValue;
                if (dimension.datatype === 'StoryBinaryLink') {
                    const cardId = value.id;
                    const story = await boardCtrl.openStoryModal(cardId);
                    dimensionValue = {
                        id: story.identifier,
                        label: story.title,
                        ordering: story.identifier,
                    };
                }
                else {
                    const result = await dimensionValueEditModal
                        .open(dimension, angular.extend({}, value), options)
                        .result
                        .catch(navigation.noop);
                    dimensionValue = result.dimensionValue;
                }
                if (dimensionValue) {
                    const originalDim = boardCtrl.getBoard().dimension(dimension.code);
                    const newValues = originalDim.withChangedValue(dimensionValue, value);
                    boardCtrl.dimensionChanged(newValues);
                }
            },
            param: [column.dimension, column.dimensionValue],
            enabled: function () {
                return $rootScope.isAdmin && column.dimension && column.dimension.canValuesBeEdited();
            },
        };
    };
    const deleteValueAction = function (boardCtrl, column) {
        return {
            label: 'Delete value …',
            method: ($event, param) => {
                $event.preventDefault();
                const dimension = param[0];
                const value = param[1];
                dimensionValueEditModal
                    .open(dimension, angular.extend({}, value), angular.extend({}, { mode: 'try-delete' }), boardCtrl)
                    .result
                    .then(() => {
                    boardCtrl
                        .refresh()
                        .then(() => boardCtrl.dimensionChanged(dimension.withoutValue(value)));
                })
                    .catch(navigation.noop);
            },
            param: [column.dimension, column.dimensionValue],
            enabled: function () {
                return $rootScope.isAdmin && column.dimension && column.dimension.canValuesBeDeleted();
            },
        };
    };
    const selectAllCardsAction = function (boardCtrl, column) {
        return {
            label: 'Select all cards',
            method: ($event, _param) => {
                $event.preventDefault();
                // $event.stopPropagation();   // necessary to avoid keeping the menu open...
                boardCtrl.selectSome(column.stories);
            },
            param: [],
        };
    };
    const collapseColumnAction = function (boardCtrl, column) {
        return {
            label: 'Collapse column',
            method: 'toggleCollapse',
            param: [column.dimension, column.dimensionValue, true],
            enabled: () => {
                return boardCtrl.getBoard().mode === 'kanban';
            },
        };
    };
    const zoomOnCardsAction = function (boardCtrl, column) {
        return {
            label: 'Zoom on cards',
            icon: 'icon-View-Grid',
            method: (_event, params) => {
                boardCtrl.switchToGridFilteredBy(params[0]);
            },
            param: [column.getFilterPairs()],
        };
    };
    const zoomOnCardsByAction = function (boardCtrl, column, byDimensions) {
        return {
            label: 'Zoom on cards by …',
            icon: 'icon-View-Kanban',
            children: byDimensions.map((dim) => {
                return {
                    label: dim.label,
                    method: (_event, params) => {
                        boardCtrl.switchToKanbanFilteredBy(params[0], params[1]);
                    },
                    param: [dim, column.getFilterPairs()],
                };
            }),
        };
    };
    const sortCardsAction = function (boardCtrl, column, byDimensions) {
        return {
            label: 'Sort cards by …',
            icon: 'icon-View-Casino',
            children: byDimensions.map((dim) => {
                return {
                    label: dim.label,
                    method: (_event, params) => {
                        boardCtrl.switchToCasinoFilteredBy(params[0], params[1]);
                    },
                    param: [dim, column.getFilterPairs()],
                };
            }),
        };
    };
    const columnActions = function (boardCtrl, column) {
        const byDimensions = boardCtrl.getRelevantDimensionsForAnchor('displayBy');
        return [
            newCardAction(boardCtrl, column),
            selectAllCardsAction(boardCtrl, column),
            editDimensionValueAction(boardCtrl, column),
            deleteValueAction(boardCtrl, column),
            {
                separator: true,
            },
            collapseColumnAction(boardCtrl, column),
            {
                separator: true,
            },
            zoomOnCardsAction(boardCtrl, column),
            zoomOnCardsByAction(boardCtrl, column, byDimensions),
            sortCardsAction(boardCtrl, column, byDimensions),
        ];
    };
    const cellActions = function (boardCtrl, column) {
        const byDimensions = boardCtrl.getRelevantDimensionsForAnchor('displayBy');
        return [
            newCardAction(boardCtrl, column),
            selectAllCardsAction(boardCtrl, column),
            {
                separator: true,
            },
            zoomOnCardsAction(boardCtrl, column),
            zoomOnCardsByAction(boardCtrl, column, byDimensions),
            sortCardsAction(boardCtrl, column, byDimensions),
        ];
    };
    const boardNavigationActions = function (boardCtrl, column) {
        const byDimensions = boardCtrl.getRelevantDimensionsForAnchor('displayBy');
        return [
            newCardAction(boardCtrl, column),
            selectAllCardsAction(boardCtrl, column),
            {
                separator: true,
            },
            zoomOnCardsAction(boardCtrl, column),
            zoomOnCardsByAction(boardCtrl, column, byDimensions),
            sortCardsAction(boardCtrl, column, byDimensions),
        ];
    };
    return {
        build: build,
        //
        newCardAction: newCardAction,
        editDimensionValueAction: editDimensionValueAction,
        //
        actionsForKanban: columnActions,
        actionsForMatrixColumn: columnActions,
        actionsForMatrixCell: cellActions,
        actionsForBoardNavigation: boardNavigationActions,
    };
}

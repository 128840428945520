import { ChartView } from './ChartView';
export default class BoardChartViewListener {
    // eslint-disable-next-line no-unused-vars
    prepare(canPrefetch) {
    }
    stateChanged(subject, newState, oldState) {
        return new Promise((resolve, reject) => {
            try {
                if (!newState.isMode('chart')) {
                    // No longer a chart
                    // => remove chartView
                    resolve(newState.clone({ chartView: undefined }));
                }
                else if (!oldState || !oldState.board.isMode('chart')) {
                    // First board state, in chart mode
                    // OR was not a chart and is now
                    // => Create a fresh new Chart instance
                    const board = newState.board;
                    const stories = newState.stories.clone({
                        board: board,
                    });
                    const view = new ChartView({
                        board: board,
                        stories: stories,
                    });
                    resolve(newState.clone({
                        board: board,
                        stories: stories,
                        chartView: view,
                    }));
                }
                else {
                    resolve(newState.clone({
                        chartView: newState.chartView.clone({
                            board: newState.board,
                            stories: newState.stories,
                        }, false),
                    }));
                }
            }
            catch (ex) {
                // eslint-disable-next-line no-console
                console.error(ex);
                reject(ex);
            }
        });
    }
}

angular
    .module('klaro')
    .directive('boardHeader', boardHeader);
function boardHeader($rootScope, boardObjectiveModal, Project, imailerService, auth) {
    return {
        template: require('@/core/board/boardHeader.html'),
        restrict: 'E',
        scope: true,
        link: function (scope) {
            scope.view = $rootScope.view;
            function refreshProjectInfo() {
                Project.get().then((p) => {
                    scope.projectName = p.name;
                    scope.projectLogo = p.logoThumbUrl;
                });
            }
            refreshProjectInfo();
            $rootScope.$on('klaro.project.changed', refreshProjectInfo);
            scope.boardIsDirty = function () {
                return scope.boardCtrl && scope.boardCtrl.boardIsDirty();
            };
            scope.boardHasObjective = function () {
                return this.boardCtrl.getBoard() && this.boardCtrl.getBoard().objective;
            };
            scope.showObjective = function (event) {
                event.stopPropagation();
                boardObjectiveModal.open(scope.boardCtrl);
            };
            scope.getBoardName = function () {
                if (!this.boardCtrl) {
                    return '';
                }
                return this.boardCtrl.getBoard().label;
            };
            scope.getBoardObjective = function () {
                if (!this.boardCtrl) {
                    return '';
                }
                return this.boardCtrl.getBoard().objective;
            };
            scope.canWrite = function () {
                return this.boardCtrl && this.boardCtrl.canWrite();
            };
            scope.saveBoard = function () {
                if (this.boardCtrl) {
                    this.boardCtrl.saveBoard();
                }
            };
            scope.resetAllChanges = function () {
                if (this.boardCtrl) {
                    this.boardCtrl.resetAllChanges();
                }
            };
            scope.openBoardSettings = function () {
                if (!this.boardCtrl) {
                    return;
                }
                if (auth.isAnonymous()) {
                    return;
                }
                scope.toggleBoardSettings('filters');
            };
            scope.imailer = imailerService;
        },
    };
}

import { Gantt } from '@klaro/corejs/views';
angular
    .module('klaro')
    .directive('boardGanttDisplay', boardGanttDisplay);
function boardGanttDisplay(events, $timeout, klaroUi, $document) {
    return {
        restrict: 'E',
        template: require('@/core/board/boardGanttDisplay.html'),
        require: '^board',
        link: function (scope, elm, attrs, board) {
            scope.scales = Gantt.Scales;
            scope.currentPeriodIndex = 0;
            function setGantt() {
                scope.gantt = board.getBoard().ganttView;
            }
            setGantt();
            board.$watch('board', setGantt, scope);
            scope.setGanttScale = function (scale) {
                board.setGanttScale(scale).then(() => {
                    updateCurrentPeriodViewed();
                    scope.goNow();
                });
            };
            scope.scaleSelected = function (scale) {
                return scope.gantt.isCurrentScale(scale);
            };
            const stickyLeftWidth = scope.gantt.stickyColumnWidth + 6; // 6px for the timepoint padding
            scope.currentPeriodName = function () {
                if (!scope.gantt.isConfigured()) {
                    return '';
                }
                if (!(scope.currentPeriodIndex in scope.gantt.navigablePeriods)) {
                    return '';
                }
                const dateFormat = scope.gantt.currentScale.period.nameFormat;
                return scope.gantt.navigablePeriods[scope.currentPeriodIndex].toFormat(dateFormat);
            };
            let updateCurrentPeriod = true;
            scope.goto = function (step) {
                let moveTo = false;
                if (step === 'next' && scope.currentPeriodIndex < scope.gantt.navigablePeriods.length - 1) {
                    scope.currentPeriodIndex++;
                    moveTo = true;
                }
                else if (step === 'previous' && scope.currentPeriodIndex > 0) {
                    scope.currentPeriodIndex--;
                    moveTo = true;
                }
                if (moveTo) {
                    const dateFormat = scope.gantt.currentScale.idFormat;
                    const periodUnitID = scope.gantt.navigablePeriods[scope.currentPeriodIndex].toFormat(dateFormat);
                    const gotoElm = $document[0].getElementById(periodUnitID);
                    if (gotoElm) {
                        const dateOffset = gotoElm.offsetLeft - stickyLeftWidth;
                        updateCurrentPeriod = false;
                        scrollGanttTo(dateOffset);
                        $timeout(() => {
                            updateCurrentPeriod = true;
                        }, 700);
                    }
                }
            };
            scope.nowExists = function () {
                return scope.gantt.nowIncluded();
            };
            function getNow() {
                const nowCell = $('main .content .timepoints.today');
                return (nowCell.length) ? nowCell[0] : null;
            }
            scope.goNow = function () {
                const now = getNow();
                if (now) {
                    const contentWidth = $('main .content')[0].offsetWidth;
                    let dateOffset = now.offsetLeft - stickyLeftWidth - ((contentWidth - stickyLeftWidth - now.offsetWidth) / 2);
                    if (klaroUi.browser() === 'chrome') {
                        const asidesLength = $document[0].getElementsByClassName('klaro-asides')[0].getBoundingClientRect().width;
                        const decksLength = $document[0].getElementsByClassName('decks')[0].getBoundingClientRect().width;
                        dateOffset -= asidesLength + decksLength;
                    }
                    scrollGanttTo(dateOffset);
                }
            };
            // We need to execute this outside of the digest cycle as the html element
            // is outside the scope of this directive
            let mainContent;
            $timeout(() => {
                mainContent = $document[0].querySelectorAll('main .content')[0];
                updateCurrentPeriodViewed();
                events.observeElement(scope, 'scroll', updateCurrentPeriodViewed, mainContent);
            }, 0, false);
            function scrollGanttTo(to) {
                mainContent.scrollTo({
                    top: mainContent.scrollTop,
                    left: to,
                });
            }
            // Update current period viewed according to horizontal scroll.
            function updateCurrentPeriodViewed() {
                if (updateCurrentPeriod) {
                    const scrollX = mainContent.scrollLeft || 0;
                    let currentlyViewedIndex = 0;
                    scope.gantt.navigablePeriods
                        .forEach(np => {
                        if ((scrollX + 6) >= np.currentAt) {
                            currentlyViewedIndex++;
                        }
                    });
                    //https://code.angularjs.org/1.6.2/docs/error/$rootScope/inprog?p0=$digest#triggering-events-programmatically
                    // We must reevaluate the value in case it was changed by a subsequent
                    // watch handler in the digest.
                    $timeout(() => {
                        scope.currentPeriodIndex = currentlyViewedIndex;
                    }, 0);
                }
            }
        },
    };
}

import equals from '../vendor/value_equals';
class Patcher {
    constructor(options = {}) {
        this.options = options;
    }
    shallow(o1, o2, undefinedVal) {
        const uniq = new Set([...Object.keys(o1), ...Object.keys(o2)]);
        return Array.from(uniq).reduce((diff, key) => {
            let ok1 = o1[key];
            let ok2 = o2[key];
            if (Array.isArray(ok1) && Array.isArray(ok2) && this.options.setSemanticsOnArrays) {
                ok1 = Object.assign([], o1[key]);
                ok2 = Object.assign([], o2[key]);
                ok1.sort();
                ok2.sort();
            }
            if (!equals(ok1, ok2)) {
                diff[key] = (ok2 === undefined ? undefinedVal : ok2);
            }
            return diff;
        }, {});
    }
}
export default Patcher;

import CasinoView from './CasinoView';
class BoardCasinoViewListener {
    // eslint-disable-next-line no-unused-vars
    prepare(canPrefetch) {
    }
    stateChanged(subject, newState, oldState) {
        return new Promise((resolve, reject) => {
            try {
                if (!newState.isMode('casino')) {
                    // No longer a casino
                    // => remove casinoView
                    resolve(newState.clone({ casinoView: undefined }));
                }
                else if (!oldState || !oldState.board.isMode('casino')) {
                    // First board state, in casino mode
                    // OR was not a casino and is now
                    // => Create a fresh new Casino instance
                    const board = newState.board;
                    const stories = newState.stories.clone({
                        board: board,
                    });
                    const view = new CasinoView({
                        board: board,
                        stories: stories,
                        folded: [],
                    });
                    resolve(newState.clone({
                        board: board,
                        stories: stories,
                        casinoView: view,
                    }));
                }
                else {
                    resolve(newState.clone({
                        casinoView: newState.casinoView.clone({
                            board: newState.board,
                            stories: newState.stories,
                        }, false),
                    }));
                }
            }
            catch (ex) {
                // eslint-disable-next-line no-console
                console.error(ex);
                reject(ex);
            }
        });
    }
}
export default BoardCasinoViewListener;

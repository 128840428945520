import { MatrixView } from './MatrixView';
export default class MatrixViewListener {
    // eslint-disable-next-line no-unused-vars
    prepare(canPrefetch) {
    }
    stateChanged(subject, newState, oldState) {
        return new Promise((resolve, reject) => {
            try {
                if (!newState.isMode('matrix')) {
                    // No longer a matrix
                    // => remove matrixView
                    resolve(newState.clone({ matrixView: undefined }));
                }
                else if (!oldState || !oldState.board.isMode('matrix')) {
                    // First board state, in matrix mode
                    // OR was not a chart and is now
                    // => Create a fresh new Chart instance
                    const board = newState.board;
                    const stories = newState.stories.clone({
                        board: board,
                    });
                    const view = new MatrixView({
                        board: board,
                        stories: stories,
                    });
                    resolve(newState.clone({
                        board: board,
                        stories: stories,
                        matrixView: view,
                    }));
                }
                else {
                    // board or stories probably changed
                    // resync the view with them
                    resolve(newState.clone({
                        matrixView: newState.matrixView.clone({
                            board: newState.board,
                            stories: newState.stories,
                        }, false),
                    }));
                }
            }
            catch (ex) {
                // eslint-disable-next-line no-console
                console.error(ex);
                reject(ex);
            }
        });
    }
}

// TODO refactor #treeshaking
import { Casino } from '@klaro/corejs/state';
const { foldTopCard, foldCards, unfoldOne, unfoldAll } = Casino;
angular
    .module('klaro')
    .directive('storyCasino', storyCasino);
function storyCasino($rootScope, hotkeys, ArrayUtils, dimensionValueEditModal, navigation) {
    return {
        restrict: 'E',
        template: require('@/core/story/storyCasino.html'),
        scope: {
            'onClick': '&',
            'canWrite': '=',
        },
        require: '^board',
        link: function (scope, _elm, _attrs, boardCtrl) {
            scope.boardCtrl = boardCtrl;
            // Casino mode
            let casinoView;
            scope.casinoDimension = null;
            function setCasinoDimension() {
                const atCasino = boardCtrl.getDimensionsAt('displayBy');
                if (atCasino.length >= 1) {
                    scope.casinoDimension = atCasino[0];
                }
                else {
                    scope.boardCtrl = boardCtrl;
                    scope.configure = {};
                    scope.dimensions = boardCtrl.getRelevantDimensionsForAnchor('displayBy');
                    scope.casinoDimension = null;
                }
            }
            boardCtrl.$watch('board', setCasinoDimension, scope);
            scope.configureCasino = function (dimension) {
                return boardCtrl.mutate((b) => {
                    return b.addNewDimension(dimension).setDimensionsAt('displayBy', [dimension]);
                });
            };
            scope.setDisplayBy = function () {
                boardCtrl.setDimensionsAt('displayBy', [scope.configure.dimension]);
            };
            function computeCasino() {
                casinoView = boardCtrl.getBoard().casinoView;
                if (!casinoView) {
                    return;
                }
                const globalContext = boardCtrl.getGlobalContext();
                scope.configure = {};
                scope.piles = casinoView.getAssignedStories(globalContext);
                scope.toDeal = casinoView.getUnassignedStories(globalContext);
                scope.storiesToDeal = casinoView.getStoriesToDeal(globalContext);
                scope.storiesToDecide = casinoView.getStoriesToDecide(globalContext);
                scope.allStoriesSelectedByPile = casinoView.getCardsDecks(globalContext).reduce((acc, cur) => {
                    const columnStoriesIds = cur.stories.map(s => s.id);
                    acc[cur.dimensionValue.id] = ArrayUtils.isSubset(columnStoriesIds, boardCtrl.selectedStoriesIds());
                    return acc;
                }, {});
                scope.casinoDimension = casinoView.getDisplayByDimension();
                scope._canEditDimensionValue = $rootScope.isAdmin && scope.casinoDimension && scope.casinoDimension.canValuesBeEdited();
                scope._canCreateValue = $rootScope.isAdmin && scope.casinoDimension && scope.casinoDimension.tagging;
                scope.piles.forEach((pile, index) => {
                    hotkeys.bindTo(scope)
                        .add({
                        combo: `alt+${scope.shortcutFor(index)}`,
                        callback: function (event) {
                            event.stopPropagation();
                            event.preventDefault();
                            const card = casinoView.getTopCard();
                            if (card) {
                                boardCtrl.moveStoryTo(card, pile.dimension, pile.dimensionValue);
                            }
                        },
                    });
                });
            }
            boardCtrl.$watch('board', computeCasino, scope);
            scope.$watch('casinoDimension', computeCasino, scope);
            scope.actionsFor = function (pile) {
                if (!pile.actions) {
                    pile.actions = [
                        {
                            label: 'New card',
                            method: 'newCard',
                            param: [pile.dimension, pile.dimensionValue],
                        },
                        {
                            label: 'Select all cards',
                            method: 'toggleAllStoriesSelected',
                            param: [pile.dimensionValue.id, pile.stories],
                        },
                        {
                            label: 'Rename / Edit ...',
                            method: 'editDimensionValue',
                            param: [pile.dimension, pile.dimensionValue],
                        },
                        {
                            label: 'Delete value ...',
                            method: 'deleteDimensionValue',
                            param: [pile.dimension, pile.dimensionValue],
                            enabled: 'canDeleteDimensionValue',
                        },
                        {
                            separator: true,
                        },
                        {
                            label: 'Move all to Unsorted',
                            method: 'unsortCards',
                            param: [pile.stories],
                        },
                    ];
                }
                return pile.actions;
            };
            scope.foldTopCard = function () {
                boardCtrl.applyStateAction((s) => foldTopCard(s));
            };
            scope.unfoldAll = function () {
                boardCtrl.applyStateAction((s) => unfoldAll(s));
            };
            scope.unfoldOne = function () {
                boardCtrl.applyStateAction((s) => unfoldOne(s));
            };
            scope.unsortCards = function (event, stories) {
                const storiesIds = stories[0].map(s => s.id);
                boardCtrl.resetAllCasinoCards(storiesIds);
            };
            scope.foldCards = function (storyIds) {
                boardCtrl.resetAllCasinoCards(storyIds, (newState) => {
                    boardCtrl.applyStateAction(() => foldCards(newState, storyIds));
                });
            };
            scope.toggleAllStoriesSelected = function (event, dimensionValueId, stories) {
                // From actions context menu
                if (Array.isArray(dimensionValueId)) {
                    stories = dimensionValueId[1];
                    dimensionValueId = dimensionValueId[0];
                    scope.allStoriesSelectedByPile[dimensionValueId] = !scope.allStoriesSelectedByPile[dimensionValueId];
                }
                if (scope.allStoriesSelectedByPile[dimensionValueId]) {
                    boardCtrl.selectSome(stories);
                }
                else {
                    boardCtrl.unselectSome(stories);
                }
            };
            scope.canIWrite = function () {
                return scope.canWrite;
            };
            scope.newCard = function (event, dimensionInfo) {
                const dimension = dimensionInfo[0];
                const dimensionValue = dimensionInfo[1];
                const defaults = {};
                defaults[dimension.code] = dimensionValue.id;
                boardCtrl.openNewStoryModal(defaults);
            };
            scope.canEditDimensionValue = function () {
                return scope._canEditDimensionValue;
            };
            scope.canCreateValue = function () {
                return scope._canCreateValue;
            };
            scope.editDimensionValue = async function ($event, param, options) {
                $event.preventDefault();
                const dimension = param[0];
                const value = param[1];
                let dimensionValue;
                if (dimension.datatype === 'StoryBinaryLink') {
                    const cardId = value.id;
                    const story = await boardCtrl.openStoryModal(cardId);
                    dimensionValue = {
                        id: story.identifier,
                        label: story.title,
                        ordering: story.identifier,
                    };
                }
                else {
                    const result = await dimensionValueEditModal
                        .open(dimension, angular.extend({}, value), options)
                        .result
                        .catch(navigation.noop);
                    dimensionValue = result.dimensionValue;
                }
                if (dimensionValue) {
                    const newValues = scope.casinoDimension.withChangedValue(dimensionValue, value);
                    boardCtrl.dimensionChanged(newValues);
                }
            };
            scope.canDeleteDimensionValue = function () {
                return $rootScope.isAdmin && scope.casinoDimension && scope.casinoDimension.canValuesBeDeleted();
            };
            scope.deleteDimensionValue = function ($event, param) {
                $event.preventDefault();
                const dimension = param[0];
                const value = param[1];
                dimensionValueEditModal
                    .open(dimension, angular.extend({}, value), angular.extend({}, { mode: 'try-delete' }), boardCtrl)
                    .result
                    .then(() => {
                    boardCtrl
                        .refresh()
                        .then(() => boardCtrl.dimensionChanged(scope.casinoDimension.withoutValue(value)));
                })
                    .catch(navigation.noop);
            };
            scope.withNewDimensionValue = function (dimensionValue) {
                boardCtrl.addDimensionValue(scope.casinoDimension, dimensionValue);
            };
            hotkeys.bindTo(scope)
                .add({
                combo: 'down',
                description: 'Fold current card',
                callback: () => scope.foldTopCard(),
            })
                .add({
                combo: 'up',
                description: 'Unfold current card',
                callback: () => scope.unfoldOne(),
            });
            scope.shortcutFor = function (index) {
                return String.fromCharCode(97 + index);
            };
            scope.stackedEmptyDescription = function () {
                return $rootScope.ctrlKeyIsPressed ? '(up / down)' : 'Drag cards here to sort them later';
            };
        },
    };
}

import DataObject from '../../utils/DataObject';
import ArrayUtils from '../../utils/ArrayUtils';
export default class TreeNode extends DataObject {
    constructor(raw) {
        super(Object.assign({ children: [] }, raw));
    }
    hasChild() {
        return this.children.length > 0;
    }
    addChild(node) {
        this.children.push(node);
        node.parent = this;
        return this;
    }
    removeChild(node) {
        this.__raw.children = ArrayUtils.without(this.__raw.children, undefined, (x) => x.id === node.id);
        return this;
    }
    get depth() {
        if (this.parent) {
            return 1 + this.parent.depth;
        }
        else {
            return 1;
        }
    }
    get treeDepth() {
        return this.dfs((memo, node) => {
            return node.depth > memo ? node.depth : memo;
        }, 0);
    }
    dfs(callback, memo, includeSelf = true) {
        let newMemo = memo;
        if (includeSelf) {
            newMemo = callback(newMemo, this);
        }
        if (newMemo !== undefined) {
            this.children.forEach((child) => {
                newMemo = child.dfs(callback, newMemo);
            });
            return newMemo;
        }
        else {
            return memo;
        }
    }
    getLinearization(includeSelf = true) {
        return this.dfs((memo, node) => {
            memo.push(node);
            return memo;
        }, [], includeSelf);
    }
}

import DataObject from '../utils/DataObject';
class DimensionDeck extends DataObject {
    get filteredValueLabel() {
        const value = this.board.filterValuesAlong(this.dimension);
        if (!value || value.length === 0) {
            return '';
        }
        return value.length > 1 ? `${value.length}` : value[0].label;
    }
    get entriesSplit() {
        return this.memoize('entriesSplit', '', () => {
            const values = this.dimension.getValues(true)
                .map(v => {
                const count = this.storyCounts.getCounts(this.dimension, v);
                return {
                    ...v,
                    deprecated: !!v.deprecated,
                    storyCount: count,
                    storyCountStr: this.countStr(count),
                };
            })
                .filter(v => this.matchesSearch(v))
                .filter(v => this.shown(v));
            return this.applyMaxValues(values);
        });
    }
    countStr(count) {
        const partials = this.storyCounts.partialResults();
        if (count.filtered === 0) {
            return partials ? '...' : '';
        }
        else {
            return `${partials ? '≥' : ''}${count.filtered}`;
        }
    }
    get entries() {
        return this.entriesSplit[0];
    }
    get hiddenEntries() {
        return this.entriesSplit[1];
    }
    get hasHidden() {
        return this.hiddenEntries.length > 0;
    }
    get hiddenCount() {
        return this.hiddenEntries.length;
    }
    get hasSearch() {
        return !!this.searchRx;
    }
    get searchAvailable() {
        return this.hasHidden || this.hasSearch || this.showAll;
    }
    shown(value) {
        const dim = this.dimension;
        const isCompact = this.board.compactDecks;
        const isNull = (value.id === null);
        const hasFilter = this.board.hasFilter(dim);
        const isStoryVisible = value.storyCount.filtered > 0;
        const isUsed = value.storyCount.all > 0;
        const isRequired = this.board.isRequired(this.dimension);
        const isDeprecated = value.deprecated;
        // 1) Values yielding filtered stories are always shown.
        if (isStoryVisible) {
            return true;
        }
        // INV: Currently filtered stories do not use the dimension value
        // 2) When filters apply, show value if either it makes sense
        //    (not null or allowed null) or is null but used (phantom)
        if (hasFilter) {
            return (!isDeprecated && (!isNull || !isRequired)) || (isNull && isUsed);
        }
        // 3) If it is compact, do not show the value since it does not
        //    yield filtered stories
        if (isCompact) {
            return false;
        }
        // INV: value yields no story, we are not in compact mode and no
        //      filter applies on the dimension
        // 4) if the value is null, show it if it is actually used by some
        //    story, or if the dimension is not required
        if (isNull) {
            return !isRequired || isUsed;
        }
        // INV: non null value, yielding no filtered story, without compact
        //      mode => show it unless deprecated
        return !isDeprecated;
    }
    matchesSearch(value) {
        if (!this.searchRx) {
            return true;
        }
        return (value.label || '').match(this.searchRx);
    }
    applyMaxValues(values) {
        if (values.length <= this.maxValuesShown) {
            return [values, []];
        }
        values.sort(this.maxValuesSorter());
        if (this.showAll) {
            return [values, []];
        }
        const left = values.slice(0, this.maxValuesShown);
        const right = values.slice(this.maxValuesShown);
        return [left, right];
    }
    maxValuesSorter() {
        return (a, b) => {
            const aSel = this.board.isFilteredBy(this.dimension, a);
            const bSel = this.board.isFilteredBy(this.dimension, b);
            if (aSel === bSel) {
                return b.storyCount.filtered - a.storyCount.filtered;
            }
            else if (aSel) {
                return -1;
            }
            else {
                return 1;
            }
        };
    }
    withMaxValuesShown(max) {
        return this.clone({ maxValuesShown: max }, false);
    }
    withShowAll() {
        return this.clone({ showAll: true }, false);
    }
    withSearch(search) {
        const rx = search && search.trim().length > 0 ? new RegExp(search, 'i') : undefined;
        return this.clone({ searchRx: rx }, false);
    }
    withBoard(board) {
        return this.clone({ board: board }, false);
    }
    withStoryCounts(storyCounts) {
        return this.clone({ storyCounts: storyCounts }, false);
    }
}
export default DimensionDeck;

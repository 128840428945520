// BABEL: Required for babel-7 & corejs polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';
// \BABEL
require('./vendors');
import './index.scss';
const requireAll = (r) => r.keys().forEach(r);
requireAll(require.context('./kernel', true, /\.(js|ts)$/));
require('./klaro.js');
requireAll(require.context('./support', true, /\.(js|ts)$/));
requireAll(require.context('./rest', true, /\.(js|ts)$/));
requireAll(require.context('./core', true, /\.(js|ts)$/));
requireAll(require.context('./auth', true, /\.(js|ts)$/));
requireAll(require.context('./pusher', true, /\.(js|ts)$/));
requireAll(require.context('./sync', true, /\.(js|ts)$/));
requireAll(require.context('./onboard', true, /\.(js|ts)$/));
requireAll(require.context('./layout', true, /\.(js|ts)$/));
requireAll(require.context('./views', true, /\.(js|ts)$/));
requireAll(require.context('./utils', true, /\.(js|ts)$/));
requireAll(require.context('./news', true, /\.(js|ts)$/));
require('./routing.js');
require('./home.js');
require('./boot.js');

import WorkspacePermissions from '../model/Board/WorkspacePermissions';
class BoardPermissionsListener {
    prepare(canPrefetch) {
    }
    stateChanged(subject, newState, oldState) {
        return new Promise((resolve, reject) => {
            const user = newState.user;
            const permissions = newState.board.workspacePermissions;
            let userPerms;
            if (user) {
                userPerms = WorkspacePermissions.usedBy(permissions, user);
            }
            else {
                userPerms = {
                    canRead: true,
                    canWrite: false,
                    canManage: false,
                };
            }
            resolve(newState.clone({
                permissions: userPerms,
                userLevel: permissions.getLevelDetailsFor(userPerms),
            }));
        });
    }
}
export default BoardPermissionsListener;

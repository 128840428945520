window.onload = () => {
    angular.element(document).ready(() => {
        const $injector = angular.injector(['ng', 'klaro.kernel']);
        const kernel = $injector.get('Kernel');
        const kernelLoading = angular.element('#kernel-loading');
        kernelLoading.text('Please wait');
        kernel
            .init()
            .then(() => {
            kernelLoading.hide();
            angular.bootstrap(document, ['klaro'], { strictDi: true });
        })
            .catch((err) => {
            console.error('Fatal error on load', err);
            kernelLoading.text(err.message);
        });
    });
};

angular
    .module('klaro')
    .directive('rightHeader', rightHeader);
function rightHeader($rootScope, navigation, Project, Chatbot) {
    return {
        template: require('@/layout/header/rightHeader.html'),
        scope: true,
        replace: true,
        require: '^?board',
        link: function (scope, element, attributes, boardCtrl) {
            scope.projectIsLocked = true;
            function refreshProjectInfo() {
                Project.get().then((p) => {
                    scope.projectIsTemplate = p.isTemplate;
                    scope.projectIsLocked = p.isLocked;
                    scope.projectTemplate = p.template;
                });
            }
            refreshProjectInfo();
            $rootScope.$on('klaro.project.changed', refreshProjectInfo);
            scope.websiteUrl = navigation.getWebsiteUrl();
            scope.gotoWebsite = function () {
                return navigation.gotoWebsite(true);
            };
            scope.chatIsOpen = function () {
                return Chatbot.isOpen();
            };
            scope.toggleChat = function () {
                Chatbot.toggle();
            };
        },
    };
}

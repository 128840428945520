import { LocalStoragePersistence } from '@klaro/corejs/utils';
angular
    .module('klaro')
    .directive('recentNews', recentNews);
function recentNews($http, $filter, storyRest, navigation, $timeout) {
    return {
        restrict: 'E',
        scope: {},
        template: require('@/news/recentNews.html'),
        link: function (scope, elm, attrs) {
            $http
                .get('https://blog.klaro.cards/api/boards/news/stories/', {
                skipKlaroAuth: true,
            })
                .then((response) => {
                scope.recentNews = extractNews(response.data);
            })
                .catch((data) => {
                console.error(data);
                // simply ignore, not a big deal if something goes wrong
                // with news
            });
            scope.openNews = function (news, $event) {
                trackNewsClicked(news);
                $event.preventDefault();
                $event.stopPropagation();
                navigation.gotoExternalUrl(news.url, true);
            };
            function extractNews(stories) {
                const read = getReadNews();
                const candidates = stories.filter(s => !read[s.id]);
                if (candidates.length == 0) {
                    return;
                }
                const chosen = candidates[Math.floor(Math.random() * candidates.length)];
                const story = storyRest.dress(chosen, {});
                const date = story['publication-date'];
                const dateStr = $filter('date')(date, 'yyyy/MM/dd');
                return {
                    id: story.id,
                    title: story.toptitle,
                    summary: story.summary,
                    url: `https://klaro.cards/blog/${dateStr}/${story.urlHash}`,
                };
            }
            const storage = new LocalStoragePersistence();
            const storageKey = 'Klaro-RecentNewsClicked';
            function trackNewsClicked(news) {
                const read = getReadNews();
                read[news.id] = true;
                storage.set(storageKey, read);
            }
            function getReadNews() {
                return storage.get(storageKey, {});
            }
        },
    };
}

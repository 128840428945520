import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { Colors } from '../../utils';
export const setupHighcharts = () => {
    HighchartsMore(Highcharts);
    Highcharts.setOptions({
        colors: [...Colors.mostUsed()],
        credits: {
            enabled: false,
        },
        chart: {
            style: {
                fontFamily: 'Nunito',
                fontSize: '16px',
            },
        },
    });
};
export { default as BoardChartViewListener } from './ChartViewListener';
export * from './ChartView';
export * from './ChartViewController';
export { Highcharts };

import { Patcher } from '../utils';
class BoardDirtyListener {
    prepare(canPrefetch) {
    }
    stateChanged(subject, newState, oldState) {
        return new Promise((resolve, reject) => {
            const oldS = newState.original.toBoardSaveRaw();
            const newS = newState.board.toBoardSaveRaw();
            const patch = (new Patcher()).shallow(oldS, newS);
            resolve(newState.clone({ isDirty: this.isDirty(patch) }));
        });
    }
    isDirty(patch) {
        const patchSize = Object.keys(patch).length;
        if (patchSize === 0) {
            return false;
        }
        else if (patchSize === 1) {
            return !Object.prototype.hasOwnProperty.call(patch, 'search');
        }
        else {
            return true;
        }
    }
}
export default BoardDirtyListener;

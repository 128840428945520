angular
    .module('klaro')
    .directive('boards', boards);
function boards(events, MyBoards, navigation, auth, boardRest, klaroUi, Project, hotkeys, $window) {
    return {
        restrict: 'E',
        template: require('@/layout/asides/boards.html'),
        link: function (scope, elm) {
            function refresh() {
                Project.get().then((p) => {
                    scope.isTemplate = p.isTemplate;
                });
                MyBoards.inCurrentWorkspace().then((bs) => {
                    scope.boards = bs;
                });
            }
            refresh();
            scope.isActive = function (url) {
                return navigation.isActive(url);
            };
            scope.isHomeDashboard = function () {
                const user = auth.getUser();
                const preferences = user ? user.preferences : null;
                return preferences && preferences.homeBoard === null;
            };
            scope.isHomeBoard = function (board) {
                const user = auth.getUser();
                const preferences = user ? user.preferences : null;
                return preferences && board.location === preferences.homeBoard;
            };
            scope.setHomeBoard = function (event, board) {
                event.stopPropagation();
                event.preventDefault();
                const userData = auth.getUser().toRaw();
                userData.preferences = angular.extend({}, auth.getUser().preferences);
                userData.preferences.homeBoard = board.location;
                userData.preferences.homeWorkspace = auth.getViewAs().code;
                return auth.updateProfile(userData);
            };
            scope.setHomeDashboard = function (event) {
                event.stopPropagation();
                event.preventDefault();
                const userData = auth.getUser().toRaw();
                userData.preferences = angular.extend({}, auth.getUser().preferences);
                userData.preferences.homeBoard = null;
                return auth.updateProfile(userData);
            };
            scope.onBoardDrop = function (srcList, srcIndex, targetList, targetIndex) {
                // Copy the item from source to target.
                targetList.splice(targetIndex, 0, srcList[srcIndex]);
                // Remove the item from the source, possibly correcting the index first.
                // We must do this immediately, otherwise ng-repeat complains about duplicates.
                if (srcList == targetList && targetIndex <= srcIndex) {
                    srcIndex++;
                }
                srcList.splice(srcIndex, 1);
                const boardIds = srcList.map((board) => {
                    return board.id;
                });
                boardRest.ordering(boardIds).catch(navigation.failed);
                // By returning true from dnd-drop we signalize we already inserted the item.
                return true;
            };
            scope.toggleBoards = function () {
                const klarosAsides = elm.closest('.main-container')[0];
                klarosAsides.classList.add('clicked');
                klarosAsides.classList.toggle('closed');
                if (!klaroUi.isMobile()) {
                    $window.localStorage.setItem('hide-aside', (klarosAsides.classList.contains('closed')));
                }
            };
            hotkeys
                .bindTo(scope)
                .add({
                combo: 'l',
                description: 'Toggle side bar',
                callback: (event) => {
                    event.preventDefault();
                    scope.toggleBoards();
                },
            });
            events.listen(scope, 'klaro.project.changed', refresh);
            events.listen(scope, 'klaro.board.changed', refresh);
            events.listen(scope, 'klaro.user.changed', refresh);
            events.listen(scope, 'klaro.viewAs.changed', refresh);
        },
    };
}

import Stories from '../model/Board/Stories';
class EmptyStoriesListener {
    prepare(canPrefetch) {
    }
    stateChanged(subject, newState, oldState) {
        return new Promise((resolve, reject) => {
            if (!oldState) {
                const stories = Stories.dress([], {
                    board: newState.board,
                    globalContext: {
                        requester: newState.user,
                        board: newState.board,
                    },
                });
                resolve(newState.clone({
                    stories: stories,
                    storiesPredicate: newState.original.getFilters(),
                }));
            }
            else {
                resolve(newState);
            }
        });
    }
}
export default EmptyStoriesListener;

angular
    .module('klaro')
    .directive('storyKanban', storyKanban)
    .directive('kanbanColumnHeader', kanbanFixedAutofollow)
    .directive('kanbanColumnFooter', kanbanFixedAutofollow);
function storyKanban($rootScope, $window, navigation, dimensionValueEditModal, $timeout, ArrayUtils, ActionsForHelper) {
    return {
        restrict: 'E',
        template: require('@/core/story/storyKanban.html'),
        scope: {
            'onClick': '&',
            'canWrite': '=',
        },
        require: '^board',
        link: function (scope, _elm, _attrs, boardCtrl) {
            scope.kanbanDimension = null;
            scope.dimensions = boardCtrl.getRelevantDimensionsForAnchor('displayBy');
            // Kanban mode
            function setKanbanDimension() {
                const atKanban = boardCtrl.getDimensionsAt('displayBy');
                if (atKanban.length >= 1) {
                    scope.kanbanDimension = atKanban[0];
                    scope.readOnlyKanban = !scope.kanbanDimension.canBeEdited();
                }
                else {
                    scope.configure = {};
                    scope.kanbanDimension = null;
                    scope.readOnlyKanban = true;
                }
            }
            boardCtrl.$watch('board', setKanbanDimension, scope);
            scope.configureKanban = function (dimension) {
                return boardCtrl.mutate((b) => {
                    return b.addNewDimension(dimension).setDimensionsAt('displayBy', [dimension]);
                });
            };
            scope.setDisplayBy = function () {
                boardCtrl.setDimensionsAt('displayBy', [scope.configure.dimension]);
            };
            function computeKanban() {
                if (!scope.kanbanDimension) {
                    return;
                }
                scope.kanban = boardCtrl
                    .getBoard()
                    .stories
                    .rollup([scope.kanbanDimension]);
                scope.allStoriesSelectedByColumn = scope.kanban.columns.reduce((acc, cur) => {
                    const columnStoriesIds = cur.stories.map(s => s.id);
                    acc[cur.dimensionValue.id] = ArrayUtils.isSubset(columnStoriesIds, boardCtrl.selectedStoriesIds());
                    return acc;
                }, {});
            }
            boardCtrl.$watch('board', computeKanban, scope);
            scope.$watch('kanbanDimension', computeKanban, scope);
            scope.noStories = function () {
                return boardCtrl.getBoard().stories.visible.length === 0;
            };
            scope.showEmptyNotice = function (column) {
                if (scope.noStories()) {
                    return false;
                }
                return (!column.stories || column.stories.length === 0) && !column.isBlackHole;
            };
            scope.hideEmptyColumns = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                boardCtrl.setCompactDisplay(true);
                computeKanban();
            };
            scope.isPhantom = function (column) {
                return column.dimensionValue.id === null && column.dimension.required;
            };
            scope.actionsFor = function (column) {
                if (!column.actions) {
                    column.actions = ActionsForHelper.actionsForKanban(boardCtrl, column);
                }
                return column.actions;
            };
            scope.canCreateValue = function () {
                return scope.kanbanDimension && scope.kanbanDimension.tagging && !boardCtrl.isCompactDisplay();
            };
            scope.withNewDimensionValue = function (dimensionValue) {
                boardCtrl.addDimensionValue(scope.kanbanDimension, dimensionValue);
            };
            scope.onValueDescriptionClick = function ($event, column, param) {
                const action = ActionsForHelper.build(ActionsForHelper.editDimensionValueAction, boardCtrl, column);
                action($event, param, { focusOn: 'description' });
            };
            scope.summariesDropdown = {
                show: false,
                element: null,
            };
            scope.toggleSummariesMenu = function ($event) {
                scope.summariesDropdown = {
                    show: !scope.summariesDropdown.show,
                    element: $event.target.closest('.summaries'),
                };
            };
            // When the kanban dimension changes, scroll back to (0,0).
            // This is necessary to make sure that column headers are correctly aligned.
            // Same for opening/closing decks.
            function rescroll() {
                $window.scrollTo(0, 0);
            }
            scope.$watch('kanbanDimension.code', rescroll);
            boardCtrl.$watch('board.decksOpen', rescroll, scope);
            scope.isCollapsed = function (column) {
                return boardCtrl.isColumnCollapsed(column.dimension, column.dimensionValue);
            };
            scope.toggleCollapse = function ($event, param) {
                if ($event.target.classList.contains('new-link')) {
                    return;
                }
                $event.preventDefault();
                $event.stopPropagation();
                const dimension = param[0];
                const dimensionValue = param[1];
                const force = param[2];
                if (force || $event.target.closest('.collapsed')) {
                    // We need to wait for the "newStoryHover" onClick event to be executed
                    // before toggling the collapsed state as it checks if the column is collapsed
                    // and if so, it will not open the "Create card" modal.
                    $timeout(() => {
                        $event.target.closest('.kanban-column').classList.add('clicked');
                        boardCtrl.toggleCollapse(dimension, dimensionValue);
                    }, 10);
                }
            };
            scope.toggleAllStoriesSelected = function (event, column) {
                event.target.blur();
                if (scope.allStoriesSelectedByColumn[column.dimensionValue.id]) {
                    boardCtrl.selectSome(column.stories);
                }
                else {
                    boardCtrl.unselectSome(column.stories);
                }
            };
        },
    };
}
/**
 * Helps a directive being in a fixed position while following user scroll
 * for horizontal alignment. Used by <kanban-header> and <kanban-footer>.
 *
 * Since the element has a fixed position (so that it stays visible when the
 * user scrolls top/down), we need to adapt the left position programatically,
 * according to the main left scroll and the fact that decks are opened or not.
 * This is the main objective of this implementation.
 */
function kanbanFixedAutofollow(klaroUi, events) {
    return {
        restrict: 'E',
        require: '^board',
        link: function (scope, elm, attrs, boardCtrl) {
            const evtElm = $('main .content')[0];
            const main = klaroUi.isMobile() ? evtElm : $('main .content')[0];
            // Adapt left position according to scroll and decks opening. Scroll left is
            // implemented in a browser-compatible way (we hope; see google/stackoverflow).
            function onScrollEventHandler() {
                const scrollY = main.scrollTop || $('body').scrollTop() || 0;
                if (scrollY > 20) {
                    elm.closest('kanban').addClass('scrolled');
                }
                else {
                    elm.closest('kanban').removeClass('scrolled');
                }
            }
            // register on scroll
            events.observeElement(scope, 'scroll', onScrollEventHandler, evtElm);
            // Listen to the board and re-adapt column positions when it changes
            // (and hence is refreshed)
            boardCtrl.$watch('board', onScrollEventHandler, scope);
        },
    };
}
